import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../environments/environment';

import { IpedidoDao } from './ipedido-dao';

@Injectable({
  providedIn: 'root'
})
export class PedidoDaoService implements IpedidoDao {


  ///EPLUS_MARKETPLACE_URL: string = "http://localhost:8080/eplusservicemarketplace/administration/produto/list/";
  private EPLUS_MARKETPLACE_URL = `${environment.gatewayApi}/marketplace/administracao/pedido`;



  constructor(private http: HttpClient) { 

  }


  /**
   * Save
   */
  save(pedidoDTO:any): Observable<any>{
    // let headers = new Headers();
    // headers: { 
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json' 
    // }


    return new Observable((observe)=>{
      
      this.http.post(this.EPLUS_MARKETPLACE_URL + '/save', 
                    pedidoDTO,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }  


  findById(pedidoId: any): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/' + pedidoId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


 


  /**
   * Lista todos os produtos
   */
  list(): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list',{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  findByOrdem(ordem: string): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/ordem/' + ordem,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }

  listByCliente(clienteId: number): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list/cliente/' + clienteId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  listByStatus(status: number): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list/status/' + status,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


}
