import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../environments/environment';

import { IservicoDao } from './iservico-dao';

@Injectable({
  providedIn: 'root'
})
export class ServicoDaoService implements IservicoDao {

  private ADMINISTRATION_URI = `${environment.gatewayApi}/administracao/servico`;



  constructor(private http: HttpClient) { 

  }

  /**
   * Save
   */
  save(servicoDTO:any): Observable<any>{
    return new Observable((observe)=>{

      console.log("Save....." + JSON.stringify(servicoDTO));
      this.http.post(this.ADMINISTRATION_URI + '/save', 
                    servicoDTO,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }  

 /**
   * 
   */
  findById(servicoId:number): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/' + servicoId ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  /**
   * 
   */
  list(): Observable<any>{
    return new Observable((observe)=>{
  
      this.http.get(this.ADMINISTRATION_URI + '/all' ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }



}
