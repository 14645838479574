import { Directive, HostListener } from '@angular/core';
import { AuthService } from '../../services/administration/user/auth.service';

@Directive({
  selector: '[eplusLogout]'
})
export class LogoutDirective {

  constructor(private authService: AuthService) { }

  @HostListener('click') onClick() {
    this.authService.logout();
  }

}
