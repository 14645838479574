import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/administration/user/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.isAuthenticate();
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {

    return this.isAuthenticate();
  }

  private isAuthenticate(): boolean {

    if (this.authService.getCurrentUser())
      return true;

    this.router.navigate(['/login']);

    return false;
  }

}
