import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../environments/environment';

import { IprodutoDao } from './iproduto-dao';

@Injectable({
  providedIn: 'root'
})
export class ProdutoDaoService implements IprodutoDao {

  ///EPLUS_MARKETPLACE_URL: string = "http://localhost:8080/eplusservicemarketplace/administration/produto/list/";
  private EPLUS_MARKETPLACE_URL = `${environment.gatewayApi}/marketplace/administracao/produto`;



  constructor(private http: HttpClient) { 

  }


  /**
   * Save
   */
  save(produtoDTO:any): Observable<any>{
    // let headers = new Headers();
    // headers: { 
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json' 
    // }
    let formData:FormData = new FormData();

  
     //    formData.append('Content-Type', 'application/json;  charset=UTF-8');
     ///    formData.append('Accept', `application/json`);
     //    formData.append('produtoDTO', produtoDTO);

     //formData.append('Content-Type', 'application/json');
     //formData.append('Accept', 'application/json');

          //formData.append('Content-Type', 'application/json; charset=UTF-8');
          //formData.append('Accept', 'application/json');
          //formData.append('produtoDTO', produtoDTO);
          // const options = {
          //   headers: { 'Content-Type': ['application/json; charset=UTF8'] },
          //   observe: 'events',
          //   reportProgress: true
          // };
          //{observe: 'events', reportProgress: true}
    return new Observable((observe)=>{
      debugger
      //headers: {'Content-Type': ['application/json; charset=UTF8']}, 
      this.http.post(this.EPLUS_MARKETPLACE_URL + '/save', 
      produtoDTO, {observe: 'events', reportProgress: true}
                    )
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }  


  findById(produtoId: any): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/' + produtoId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  listByCanalVenda(canalVenda: any): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list/canalvenda/' + canalVenda,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })

  }



  /**
   * Lista todos os produtos
   */
  list(): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list',{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  uploadS3(produtoDTO: any, file: any): Observable<any> {
debugger

    return new Observable((observe)=>{
      let formData:FormData = new FormData();
      debugger
      formData.append('file', file);      
      formData.append('Accept', `application/json`);
      formData.append('produtoDTO', produtoDTO);
      //formData.append('Content-Type', null);

      this.http.post(this.EPLUS_MARKETPLACE_URL + '/upload/imagem', 
                    formData,
                    {headers: {'Content-Type': ['multipart/form-data; charset=utf-8']}, observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
            case HttpEventType.UploadProgress:
            const kbUpload = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'uploadProgress', data: kbUpload});
            break;


          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }



}
