import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';
import { analyzeAndValidateNgModules } from '@angular/compiler';


import { IclienteResponsavelEvents } from './icliente-responsavel-events';
import { IclienteResponsavelService } from './icliente-responsavel-service';
import { ClienteResponsavelDaoService } from '../../dao/responsavel/cliente-responsavel-dao.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteResponsavelService implements IclienteResponsavelService {

 
  private subject = new Subject<any>();

  private _super:IclienteResponsavelEvents;

  constructor(private clienteResponsavelDaoService: ClienteResponsavelDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IclienteResponsavelEvents){
      this._super = _super;
    }

   /**
     * 
     */
    save(responsavelDTO):void{
      this.clienteResponsavelDaoService.save(responsavelDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseClienteResponsavelSave(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }




    /**
     * 
     */
    list():void{
      this.clienteResponsavelDaoService.list()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseClienteResponsavelList(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
    






  








}