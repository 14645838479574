import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';
import { analyzeAndValidateNgModules } from '@angular/compiler';


import { IclienteEvents } from '../service/icliente-events';
import { IclienteService } from './icliente-service';
import { ClienteDaoService } from '../dao/cliente-dao.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService implements IclienteService {

 
  private subject = new Subject<any>();

  private _super:IclienteEvents;

  constructor(private clienteDaoService: ClienteDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IclienteEvents){
      this._super = _super;
    }

   /**
     * 
     */
    clienteSave(clienteDTO):void{
      this.clienteDaoService.clienteSave(clienteDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    debugger
                    this._super.onResponseClienteSave(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }



    /**
     * 
     */
    clienteFindById(clienteId:number):void{
      this.clienteDaoService.clienteFindById(clienteId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseClienteFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
 
    /**
     * 
     */
    clienteFindByUUID(clienteUUID:number):void{
      this.clienteDaoService.clienteFindByUUID(clienteUUID)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseClienteFindByUUID(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }



    /**
     * 
     */
    clienteList():void{
      this.clienteDaoService.clienteList()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseClienteList(event.data);
                    break;
                    
                  case 'downProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
    






  








}