import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';

import { IpedidoEvents } from '../service/ipedido-events';

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { IpedidoService } from './ipedido-service';

import { PedidoDaoService } from '../dao/pedido-dao.service';

@Injectable({
  providedIn:'root'
})
export class PedidoService implements IpedidoService {


  private subject = new Subject<any>();

  private _super:IpedidoEvents;

  constructor(private pedidoDaoService: PedidoDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IpedidoEvents){
      this._super = _super;
    }


   /**
     * 
     */
    save(pedidoDTO:any):void{
      this.pedidoDaoService.save(pedidoDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePedidoSave(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    
    findById(pedidoId: any): void {
      
      this.pedidoDaoService.findById(pedidoId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));                  
                    this._super.onResponsePedidoFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


 
 
    /**
     * 
     */
    list():void{
      this.pedidoDaoService.list()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePedidoList(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    findByOrdem(ordem: string): void {
      this.pedidoDaoService.findById(ordem)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));                  
                    this._super.onResponsePedidoFindByOrdem(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    listByCliente(clienteId: number): void {
      this.pedidoDaoService.listByCliente(clienteId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePedidoListByCliente(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }

    listByStatus(status: number): void {
      this.pedidoDaoService.listByStatus(status)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePedidoListByStatus(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
}