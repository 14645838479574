import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../environments/environment';

import { IusuarioDao } from './iusuario-dao';

@Injectable({
  providedIn: 'root'
})
export class UsuarioDaoService implements IusuarioDao {


  private ADMINISTRATION_URI = `${environment.gatewayApi}/administracao/usuario`;



  constructor(private http: HttpClient) { 

  }


  /**
   * Save
   */
  save(usuarioDTO:any): Observable<any>{
    return new Observable((observe)=>{

      ///console.log("Save....." + JSON.stringify(usuarioDTO));
      this.http.post(this.ADMINISTRATION_URI + '/marketplace/save', 
                    usuarioDTO,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }  

 /**
   * 
   */
  findById(usuarioId:Number): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/' + usuarioId ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  verificarEmail(usuarioDTO: any): Observable<any> {
    return new Observable((observe)=>{
     
///console.log("######usuarioDTO" + JSON.stringify(usuarioDTO));

      this.http.post(this.ADMINISTRATION_URI + '/verificarEmail', 
                    usuarioDTO,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }



  /**
   * 
   */
  list(): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/list' ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }

  /**
   * 
   */
  listByClienteId(clienteId:number): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/list/client/' + clienteId ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }



  decrypt(senha: String): Observable<any> {
    //throw new Error('Method not implemented.');
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/decrypt/' + senha ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            debugger
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  } 

  /**
   * decryptPost
   */
  decryptPost(usuarioDTO:any): Observable<any>{
    return new Observable((observe)=>{

      ///console.log("Save....." + JSON.stringify(usuarioDTO));
      this.http.post(this.ADMINISTRATION_URI + '/decrypt', 
                    usuarioDTO,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  } 

}
