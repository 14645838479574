import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../environments/environment';

import { IclienteDao } from './icliente-dao';

@Injectable({
  providedIn: 'root'
})
export class ClienteDaoService implements IclienteDao {

  private ADMINISTRATION_URI = `${environment.gatewayApi}/administracao/cliente`;



  constructor(private http: HttpClient) { 

  }

  /**
   * Save
   */
  clienteSave(clienteDTO:any): Observable<any>{
    return new Observable((observe)=>{

      //console.log("Save....." + JSON.stringify(clienteDTO));
      this.http.post(this.ADMINISTRATION_URI + '/save', 
                    clienteDTO,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }  

 /**
   * 
   */
  clienteFindByUUID(clienteUUID:number): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/uuid/' + clienteUUID ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


/**
   * 
   */
  clienteFindById(clienteId:number): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/' + clienteId ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }




  /**
   * 
   */
  clienteList(): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.ADMINISTRATION_URI + '/list' ,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'progress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }
}
