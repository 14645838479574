import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewEncapsulation, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormControlDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter } from '@angular/material';
import { ActivatedRoute} from '@angular/router';

import { MatStepper } from '@angular/material';

import {debounceTime, distinctUntilChanged, map, startWith, tap,  switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject'

 import { IpartnerEvents } from '../../../partner/template/business/service/ipartner-events';
 import { PartnerService } from '../../../partner/template/business/service/partner.service';

// import { ClienteService } from '../../../administration/cliente/business/service/cliente.service';
// import { IclienteEvents } from '../../../administration/cliente/business/service/icliente-events';

// import { IpesquisaEvents } from '../../produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/ipesquisa-events';
// import { PesquisaService } from '../../produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/pesquisa.service';
// import { ServicoDaoService } from '../../../administration/servico/business/dao/servico-dao.service';


//eplus\eplusbackoffice\src\app\eplus\marketplace\pedido\application\component\catalogo\catalogo-produto.component.ts
// import { CatalogoProdutoComponent } from './component/catalogo/catalogo-produto.component';

@Component ({
  selector: 'eplus-store-partner',
  templateUrl: './store-partner.html',
  styleUrls: ['./store-partner.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip-custom-class-example.css` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,   
})
export class StorePartnerComponent implements OnInit, IpartnerEvents {


   formGroup: FormGroup;

  //  @ViewChild(CatalogoProdutoComponent)
  //  private catalogoProdutoComponent: CatalogoProdutoComponent;


  //  private debouncePedido:Subject<any> = new Subject<any>();
  
  @ViewChild('stepper') private myStepper: MatStepper;
  isLinear = true;
  isStepperProduto=false;
  isStepperCliente=false;

  clienteDTO:any = {endereco:{}, responsavel:{}, partner:{}};
  responsavelDTO:any={};

  
  partnerDTO:any={};


  // @Input()
  // acao:number=1; //1 Criar novo pedido, 2 Editar pedido, 3 Aprovar pedido, 4 Cancelar pedido 

  //@Input()
  pedidoDTO:any = {id:null, 
                   clienteId:null, 
                   ordem:null, 
                   data:null, 
                   status:null,
                   canalVenda:1,
                  
                   
                   dados:{recorrente:null,
                          tipoVenda:null,                    
                          tipoDesconto:1,
                          numeroPedido:null,
                          
                          itens:[]}
                  }; 
  
  @Output() 
  updates = new EventEmitter();

  
  constructor(private alerta: MatSnackBar,
              private route: ActivatedRoute,
              private dateAdapter: DateAdapter<any>,
              private partnerService: PartnerService) { 

      
      this.dateAdapter.setLocale('pt-br');
      ///this => Esta prevalecendo ListClienteComponent
      

      partnerService.eventSubscribe(this);
      
  }

  ngOnInit() {

    /**
     * Aguarda 3s apos onUpdates ser executado
     */
    //this.debouncePedido.pipe(debounceTime(3000)).subscribe(()=>{this.savePedido();});


    this.route.params.subscribe(params => {
      //console.log("Url Params: " + JSON.stringify(params));

      if(params['uuid']!=null){
        this.partnerService.findByUUID(params['uuid']);
      }else if(params['dominio']!=null){
        this.partnerService.findByDominio(params['dominio']);     
      }else{
        //console.log("Parceiro não foi informado!!!!");
      }
    });



    this.route.queryParams.subscribe(params => {

      //console.log("Link Params: " + JSON.stringify(params));

      if(params["canal"]!=null){
        //this.pedidoDTO = JSON.parse(params["pedido"]);
      }
      if(params["key"]!=null){ //Identifica a origem
        //this.pedidoDTO = JSON.parse(params["pedido"]);
      }


    });   
  }

  ngAfterViewInit(){


  }


  // public onEditPedido(pedidoDTO:any):void{
  //   debugger
  //   this.pedidoDTO = pedidoDTO; 
    
  //   this.clienteService.clienteFindById(this.pedidoDTO.clienteId);
  // }

  ngOnChanges(changes){
    //console.log("Maker change: " + JSON.stringify(changes));

    // debugger
    // this.clienteDTO.id = changes.pedidoDTO.currentValue.clienteId;
    // this.clienteService.clienteFindById(this.clienteDTO.id);
  }


  onUpdates() {
    //console.log("Updates...");
    
    /**
     * Save pedido
     */
    // this.debouncePedido.next(this.pedidoDTO);
  }

  onUpdatePedido(event:any){
    this.pedidoDTO = event.pedidoDTO;
    debugger
  }
    
    
    onProduto(event:any){
    //console.log("Produto recebido do catalogo..." + JSON.stringify(event));

    this.pedidoDTO.dados.itens=[];
    this.pedidoDTO.dados.itens.push({tipoItem:1, id:event.produtoDTO.id, produto:event.produtoDTO});
    
    //this.isLinear=false;
    //this.isStepperProduto=true;
    //this.myStepper.next();
    //this.myStepper.selectedIndex = 1;

    window.scroll(0, 0);

    this.isStepperProduto=true;
    this.myStepper.selected.completed = true;
    //this.myStepper.selected.editable = false;
    this.myStepper.next();


  }


  onPedidoGerado($event){
    this.pedidoDTO = $event.pedidoDTO;
  }

  onUpdateCliente(event:any){
    //console.log("Cliente recebido do eplus-store-cliente..." + JSON.stringify(event));
    this.clienteDTO = event.clienteDTO;


    if(this.clienteDTO.responsavel != null && this.clienteDTO.responsavel.id != null){
      this.isStepperCliente=true;
    }
  }

  onUpdateResponsavel(event:any){
    this.responsavelDTO = event.responsavelDTO;
    //console.log("Responsavel recebido do eplus-store-cliente..." + JSON.stringify(event));
  
  
    //if(this.responsavelDTO.id != null){
      this.isStepperCliente=true;
      //this.myStepper.selectedIndex=2;
      this.myStepper.selected.completed = true;
    //}
  
  }


  
  onSelecionaStepDados($event){
      // this.backofficeDTO=data;
    if(this.pedidoDTO.dados.itens.length==0){
        this.alerta.open("Selecione um produto para continuar.", "ENTENDI", {
        duration: 10000//, 
        //verticalPosition: 'top'
        });
    }
  }



  onRemoverItem(event:any){
    //console.log("1 - remover item: " + JSON.stringify(event));
    //this.isStepperCliente=false;
    this.myStepper.selectedIndex=0;
    this.myStepper.selected.completed = false;
  }




  novoPedido(){

    this.pedidoDTO.id=null;
    this.pedidoDTO.ordem=null;
    this.pedidoDTO.data=null;
    this.pedidoDTO.status=null;
    this.pedidoDTO.dados.itens=[];
 


    localStorage.setItem('eplusclient', JSON.stringify({uuid:this.clienteDTO.clienteUUID, ordemid: null}));
    
    this.myStepper.selectedIndex=0;
    this.myStepper.selected.completed = false;
  }


  onResponsePartnerSave(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponsePartnerFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponsePartnerList(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponsePartnerFindByUUID(data: any): void {
    //throw new Error("Method not implemented.");
    
    //console.log("Partner data: " + JSON.stringify(data));
    if(data!=null){
      this.partnerDTO = data;
      this.partnerDTO.template = JSON.parse(data.template);
      //console.log("Partner partnerDTO: " + JSON.stringify(this.partnerDTO));
    } else {

    }   
  }


  onResponsePartnerFindByDominio(data: any): void {
    //throw new Error("Method not implemented.");
    
    //console.log("onResponsePartnerFindByDominio Partner data: " + JSON.stringify(data));
    if(data!=null){
      this.partnerDTO = data;
      this.partnerDTO.template = JSON.parse(data.template);
      //console.log("Partner partnerDTO: " + JSON.stringify(this.partnerDTO));
    } else {

    }    
  }



  onResponsePartnerUpload(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onDownloadProgress(): void {
    //throw new Error("Method not implemented.");
  }
  onUploadProgress(): void {
    //throw new Error("Method not implemented.");
  }


}
