import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS : Menu[] = [
  {
    state: '/',
    name: 'DASHBOARD',
    type: 'link',
    icon: 'explore'
  },

  {
    state: 'administration',
    name: 'ADMINISTRAÇÃO',
    type: 'sub',
    icon: 'explore',
    children: [
      {state: 'servico', name: 'Serviço', type:'link'},
      {state: 'cliente', name: 'Cliente', type:'link'},     
      {state: 'organograma', name: 'Organograma', type:'link'}
    ]    
  },
  {
    state: 'partner',
    name: 'PARCEIRO',
    type: 'sub',
    icon: 'explore',
    children: [
      {state: 'template', name: 'Cadastro', type:'link'}

    ]
  },

  {
    state: 'marketplace',
    name: 'MARKETPLACE',
    type: 'sub',
    icon: 'explore',
    children: [
      {state: 'produto', name: 'Produto', type:'link'},
      {state: 'pedido', name: 'Pedido', type:'link'}
    ]
  },
  {
    state: '/',
    name: 'EDUCORP',
    type: 'link',
    icon: 'explore'
  },

  {
    state: 'intermarketing',
    name: 'INTERMARKETING',
    type: 'sub',
    icon: 'explore',
    children: [
      //{state: 'mensagem', name: 'Mensagem', type:'link'},
      {state: 'publicar', name: 'Publicar', type:'link'},
    ]
  },

  {
    state: 'disclosure',
    name: 'DENÚNCIA',
    type: 'sub',
    icon: 'explore',
    children: [
      {state: 'denuncia', name: 'Aprovar/Reprovar', type:'link'},
    ]
  },
  {
    state: '/',
    name: 'EMAISWEB',
    type: 'sub',
    icon: 'explore',
    children: [      
      {state: 'usuario', name: 'Usuário', type:'link'}      
    ]      
  }  
  ,
  {
    state: '/',
    name: 'AGENTE DE MUDANÇA',
    type: 'sub',
    icon: 'explore',
    children: [      
      {state: 'usuario', name: 'Usuário', type:'link'}      
    ]      
  }  
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
