import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';
import { analyzeAndValidateNgModules } from '@angular/compiler';


import { IusuarioEvents } from './iusuario-events';
import { IusuarioService } from './iusuario-service';
import { UsuarioDaoService } from '../dao/usuario-dao.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService implements IusuarioService {


 
  private subject = new Subject<any>();

  private _super:IusuarioEvents;

  constructor(private usuarioDaoService: UsuarioDaoService) { 
    
  }


    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IusuarioEvents){
      this._super = _super;
    }

   /**
     * 
     */
    save(usuarioDTO:any, backoffice:boolean):void{
      this.usuarioDaoService.save(usuarioDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    if(backoffice==true){
                      this._super.onResponseBackofficeSave(event.data);
                    }else{
                      this._super.onResponseUsuarioSave(event.data);
                    }
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    verificarEmail(usuarioDTO: any): void {
      this.usuarioDaoService.verificarEmail(usuarioDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("111111111111Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseVerificarEmail(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
  



    /**
     * 
     */
    findById(usuarioId:Number):void{
      this.usuarioDaoService.findById(usuarioId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseUsuarioFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    /**
     * 
     */
    list():void{
      this.usuarioDaoService.list()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseUsuarioList(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
    

    listByClienteId(clienteId: number): void {
      this.usuarioDaoService.listByClienteId(clienteId).subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseUsuarioListByClienteId(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
       );

    }




    decrypt(senha: string): void {
      this.usuarioDaoService.decrypt(senha).subscribe(event=>{
        switch (event.type) {
          case 'start':
            console.log("start request...");
            break;

          case 'response':
            //this.produtoList = event.data;
            //console.log("Resposta:  " + JSON.stringify(event.data));
            debugger
            this._super.onResponseDecrypt(event.data);
            break;
            
          case 'downloadProgress':
            this._super.onDownloadProgress();
            console.log(`Download in progress! ${ event.data }Kb loaded`);
            break;
        }
      }, 
       error => console.log("error" + error)
      );
    }  



   /**
     * 
     */
    decryptPost(usuarioDTO:any):void{
      this.usuarioDaoService.decryptPost(usuarioDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));

                      this._super.onResponseDecryptPost(event.data);
                    
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }




}