import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';

import { IavalicorpEvents } from '../service/iavalicorp-events';

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { IavalicorpService } from './iavalicorp-service';

import { AvalicorpDaoService } from '../dao/avalicorp-dao.service';

@Injectable({
  providedIn: 'root'
})
export class AvalicorpService implements IavalicorpService {

  private subject = new Subject<any>();

  private _super:IavalicorpEvents;

  constructor(private avalicorpDaoService: AvalicorpDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IavalicorpEvents){
      this._super = _super;
    }


    cloneAvalicorp(clienteId: any, avalicorpId: any): void {
      this.avalicorpDaoService.cloneAvalicorp(clienteId, avalicorpId)
      .subscribe(event=>{
        switch (event.type) {
          case 'start':
            //console.log("start request...");
            break;

          case 'response':
            debugger
            //this.produtoList = event.data;
            //console.log("Resposta:  " + JSON.stringify(event.data));                  
            this._super.onResponseCloneAvalicorp(event.data);
            break;
            
          case 'downloadProgress':
            this._super.onDownloadProgress();
            //console.log(`Download in progress! ${ event.data }Kb loaded`);
            break;
        }
      }, 
       error => console.log("error" + error)
    );

    }
  


    
    findById(clienteId:any, avalicorpId: any): void {
      
      this.avalicorpDaoService.findById(clienteId, avalicorpId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));                  
                    this._super.onResponseAvalicorpFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    /**
     * 
     */
    listByClienteId(clienteId:any):void {
      debugger
      this.avalicorpDaoService.listByClienteId(clienteId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseAvalicorpListByClienteId(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }

}