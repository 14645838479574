import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './middleware/guards/auth.guard';



import { StoreEmaiswebComponent } from './eplus/sales-channel/emaisweb/application/store-emaisweb.component';

import { StorePartnerComponent } from './eplus/sales-channel/partner/application/store-partner.component';
import { StorePartnerLeadComponent } from './eplus/sales-channel/partner/application/store-partner-lead.component';
import { StorePartnerProductComponent } from './eplus/sales-channel/partner/application/store-partner-product.component';



const routes: Routes = [
  {
    path: '', component: AdminLayoutComponent,
    canActivate: [AuthGuard], canLoad: [AuthGuard],
    children: [
      { path: '', loadChildren: './dashboard/dashboard.module#DashboardModule' },
      { path: 'administration', loadChildren: './eplus/administration/administration.module#AdministrationModule' },
      { path: 'administration', loadChildren: './eplus/company/company.module#CompanyModule' },
      { path: 'client', loadChildren: './eplus/company/company.module#CompanyModule' },
      { path: 'partner', loadChildren: './eplus/partner/partner.module#PartnerModule' },

      { path: 'intermarketing', loadChildren: './eplus/intermarketing/intermarketing.module#IntermarketingModule' },
      { path: 'disclosure', loadChildren: './eplus/disclosure/disclosure.module#DisclosureModule' },
      { path: 'marketplace', loadChildren: './eplus/marketplace/marketplace.module#MarketplaceModule' },
      
    ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: './session/session.module#SessionModule'
    }]
  },
  {path:'store/site', component: StoreEmaiswebComponent},
  {path:'store/partner/:dominio', component: StorePartnerComponent},
  
  {path:'store/partner/uuid/:uuid', component: StorePartnerComponent},
  {path:'store/partner/uuid/:uuid/lead/product/:productid', component: StorePartnerLeadComponent},
  {path:'store/partner/uuid/:uuid/product/:productid', component: StorePartnerProductComponent},
  
  {path:'store/platform', component: StoreEmaiswebComponent},
  {path:'store/backoffice', component: StoreEmaiswebComponent},

  { path: '**', redirectTo: '404' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
