import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';

import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private URI_LOGIN = `${environment.gatewayApi}/login/`;

  constructor(private http: HttpClient, private router: Router) { }

  authenticate(email: string, senha: string): Observable<any> {
    return this.http.post(`${this.URI_LOGIN}autenticar/`, { email: email, senha: senha }, { observe: 'response' })
      .pipe(switchMap((response) => {
        this.setUserToken(response.headers.get('token'));
        return of(response.body);
      }));
  }

  setUserToken(token: string) {
    if (!token) {
      window.localStorage.clear();
      return;
    }
    window.localStorage.setItem("token", token);
    this.setCurrentUser(JSON.parse(atob(token.split(".")[1]))["user"])
  }

  getToken() {
    return window.localStorage.getItem("token");
  }

  logout() {
    this.setUserToken(null);
    this.router.navigate(['/login']);
  }

  setCurrentUser(currentUser) {
    currentUser == null ? window.localStorage.removeItem("usuarioLogado") :
      window.localStorage.setItem("usuarioLogado", JSON.stringify(currentUser))
  }

  getCurrentUser() {
    return JSON.parse(window.localStorage.getItem("usuarioLogado"));
  }

}
