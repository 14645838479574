import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../../../../../../../../environments/environment';

import { IcursoDao } from './icurso-dao';

@Injectable({
  providedIn: 'root'
})
export class CursoDaoService implements IcursoDao {

  ///EPLUS_MARKETPLACE_URL: string = "http://localhost:8080/eplusservicemarketplace/administration/produto/list/";
  private EPLUS_MARKETPLACE_URL = `${environment.gatewayApi}/education/administration/builder/curso`;

  private EPLUS_CLONE_CURSO_URL = `${environment.gatewayApi}/education/administration/clone/curso`;

  //http://localhost:8080/eplusserviceeducation/administration/clone/curso/cliente/52/curso/48

  constructor(private http: HttpClient) { 

  }


  findById(clienteId:any, cursoId: any): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/cliente/' + clienteId + "/curso/" + cursoId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }

  /**
   * Lista todos os produtos
   */
  listByClienteId(clienteId:any): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list/cliente/' + clienteId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            ///console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }



  /**
   * Clona curso para cliente
   */
   cloneCursoToCliente(clienteId:any, cursoId:any): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_CLONE_CURSO_URL + '/cliente/' + clienteId + '/curso/' + cursoId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            ///console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }





  uploadS3(produtoDTO: any, file: any): Observable<any> {
    return new Observable((observe)=>{
      let formData:FormData = new FormData();

      formData.append('file', file);
      formData.append('Content-Type', 'application/json');
      formData.append('Accept', `application/json`);
      formData.append('produtoDTO', produtoDTO);

      this.http.post(this.EPLUS_MARKETPLACE_URL + '/upload/imagem', 
                    formData,
                    {observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
            case HttpEventType.UploadProgress:
            const kbUpload = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'uploadProgress', data: kbUpload});
            break;


          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }



}
