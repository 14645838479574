import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';



import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'; 

import { TextMaskModule } from 'angular2-text-mask';

import { CurrencyMaskModule } from "ng2-currency-mask";
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);


import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,

} from '@angular/material';


import { salesChannelRoutes } from './sales-channel-routing.module';


//Site Store (Catalogo/Cliente/Pedido)
import { StoreEmaiswebComponent } from './emaisweb/application/store-emaisweb.component';
//Catalogo produto
import { StoreEmaiswebCatalogDetailComponent } from './emaisweb/application/component/catalog/store-emaisweb-catalog-detail.component';
//Dados Cliente
import { StoreEmaiswebClienteComponent } from './emaisweb/application/component/cliente/store-emaisweb-cliente.component';
//Dados Pedido
import { StoreEmaiswebPedidoComponent } from './emaisweb/application/component/pedido/store-emaisweb-pedido.component';
//Item (Carrinho)
import { StoreEmaiswebCarrinhoComponent } from './emaisweb/application/component/carrinho/store-emaisweb-carrinho.component';



//Partner Store (Catalogo/Cliente/Pedido)
import { StorePartnerComponent } from './partner/application/store-partner.component';
//Catalogo produto
import { StorePartnerCatalogDetailComponent } from './partner/application/component/catalog/store-partner-catalog-detail.component';
//Dados Cliente
import { StorePartnerClienteComponent } from './partner/application/component/cliente/store-partner-cliente.component';
//Dados Pedido
import { StorePartnerPedidoComponent } from './partner/application/component/pedido/store-partner-pedido.component';
//Item (Carrinho)
import { StorePartnerCarrinhoComponent } from './partner/application/component/carrinho/store-partner-carrinho.component';





import { EplusTermoUsoDialog } from './emaisweb/application/component/termo-uso/eplus-termo-uso-dialog.component';
import { EplusPartnerTermoUsoDialog } from './partner/application/component/termo-uso/eplus-partner-termo-uso-dialog.component';
import { StorePartnerLeadComponent } from './partner/application/store-partner-lead.component';
import { StorePartnerProductComponent } from './partner/application/store-partner-product.component';


//import { QuillModule } from 'ngx-quill';
//import { EditorComponent } from './editor/editor.component';



@NgModule({
  imports: [
    CommonModule,
    TextMaskModule,
    
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule,
    
    //QuillModule,
    FormsModule, 
    ReactiveFormsModule,

    CurrencyMaskModule,

    

    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    RouterModule.forChild(salesChannelRoutes)    
  ],
  exports: [
            StoreEmaiswebComponent,
            StoreEmaiswebCatalogDetailComponent,
            StoreEmaiswebClienteComponent,
            StoreEmaiswebPedidoComponent,
            StoreEmaiswebCarrinhoComponent,


            StorePartnerComponent,
            StorePartnerLeadComponent,
            StorePartnerProductComponent,
            StorePartnerCatalogDetailComponent,
            StorePartnerClienteComponent,
            StorePartnerPedidoComponent,
            StorePartnerCarrinhoComponent,          


            ],
  declarations: [
                StoreEmaiswebComponent,
                StoreEmaiswebCatalogDetailComponent,
                StoreEmaiswebClienteComponent,
                StoreEmaiswebPedidoComponent,
                StoreEmaiswebCarrinhoComponent,
                EplusTermoUsoDialog,
                

                
                StorePartnerComponent,
                StorePartnerLeadComponent,
                StorePartnerProductComponent,
                StorePartnerCatalogDetailComponent,
                StorePartnerClienteComponent,
                StorePartnerPedidoComponent,
                StorePartnerCarrinhoComponent,
                EplusPartnerTermoUsoDialog,

                
              ], 
  entryComponents: [EplusTermoUsoDialog, EplusPartnerTermoUsoDialog],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SalesChannelModule { }
