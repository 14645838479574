import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';

import { FormBuilder, FormGroup, FormControl, Validators, FormControlDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import {debounceTime, distinctUntilChanged, map, startWith, tap,  switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject'

import { ClienteService } from '../../../../../administration/cliente/business/service/cliente.service';
import { IclienteEvents } from '../../../../../administration/cliente/business/service/icliente-events';

import { UsuarioService } from '../../../../../administration/usuario/business/service/usuario.service';
import { IusuarioEvents } from '../../../../../administration/usuario/business/service/iusuario-events';

import { ClienteResponsavelService } from '../../../../../administration/cliente/business/service/responsavel/cliente.responsavel.service';
import { IclienteResponsavelEvents } from '../../../../../administration/cliente/business/service/responsavel/icliente-responsavel-events';

import { IpedidoEvents } from '../../../../../marketplace/pedido/business/service/ipedido-events';
import { PedidoService } from '../../../../../marketplace/pedido/business/service/pedido.service';



@Component ({
  selector: 'eplus-store-partner-cliente',
  templateUrl: './store-partner-cliente.component.html',
  styleUrls: ['./store-partner-cliente.component.scss'],
  providers: [PedidoService, ClienteService]
})
export class StorePartnerClienteComponent implements OnInit,
                                             IclienteEvents,
                                             IusuarioEvents,
                                             IclienteResponsavelEvents,
                                             IpedidoEvents {

  private self = this;                                           
  
  private debounceCliente:Subject<any> = new Subject<any>();
  private debounceEndereco:Subject<any> = new Subject<any>();
  private debounceResponsavel:Subject<any> = new Subject<any>();
  private debounceBackoffice:Subject<any> = new Subject<any>();

  
  dadosFormValid=false;

  cnpjmask = [/\d/, /\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/,/\d/,'/', /\d/, /\d/, /\d/, /\d/, '-',/\d/, /\d/];


  clienteDTO:any={};
  //{contrato:{dados:{responsavel:null}}};
  enderecoDTO:any={};
  responsavelDTO:any={};

  usuarioDTO={
    id:null,
    clienteId:null,
    usuarioId:null,
    nome:"",
    email:"",    
    senha:null,
    enabled:true, //Evita enviar email
     roles: [
      {
        id: 1,
        nome: "ROLE_ADMIN",
        titulo: "Administrador",
        descricao: "Tem acesso a todos os serviços da plataforma como administrador."
      }
    ]               
  };







  @Input()
  pedidoDTO:any;
  pedidosExistente=[];

  @Input()
  partnerDTO:any;

  @Output()
  updatePedido = new EventEmitter();


  @Output()
  updateCliente = new EventEmitter();

  @Output()
  updateResponsavel = new EventEmitter();



  constructor(private alerta: MatSnackBar,
              private clienteService: ClienteService,
              private usuarioService: UsuarioService,            
              private clienteResponsavelService: ClienteResponsavelService,
              private pedidoService: PedidoService ) { 
      
    
                clienteService.eventSubscribe(this);
                usuarioService.eventSubscribe(this);
                clienteResponsavelService.eventSubscribe(this); 
                pedidoService.eventSubscribe(this);
            
            
                //* Verifica se cliente esta no browser */
                if(localStorage.getItem('eplusclient')!=null){
                  /////var clienteUUID = JSON.parse(localStorage.getItem('eplusclient')).uuid;
                  //////clienteService.clienteFindByUUID(clienteUUID);
                }
                
      
  }
  onResponseDecryptPost(data: any): void {
    //throw new Error('Method not implemented.');
  }
  onResponseDecrypt(data: any): void {
    //throw new Error('Method not implemented.');
  }

  ngAfterViewInit(){


  }

  ngOnInit() {
    /**
     * Aguarda 3s apos onUpdates ser executado
     */
    this.debounceCliente.pipe(debounceTime(3000)).subscribe(() => { this.onUpdatesCliente();});
    //this.debounceEndereco.pipe(debounceTime(2000)).subscribe(()=>{this.onUpdatesEndereco();});
    this.debounceResponsavel.pipe(debounceTime(3000)).subscribe(()=>{this.onUpdatesResponsavel();});
    //this.debounceBackoffice.pipe(debounceTime(3000)).subscribe(()=>{this.onUpdatesBackoffice();});

  }

  onDigitandoDadosCliente(event:any){

    this.debounceCliente.next(event.clienteDTO);
  }

  onDigitandoEnderecoCliente(event:any){
    //this.debounce.next(event.clienteDTO);
    this.debounceCliente.next(event.clienteDTO);
  }


  onDigitandoResponsavelCliente(event){
    this.debounceResponsavel.next(event.responsavelDTO);  
  }

  onUpdatesCliente(){
    if(this.clienteDTO.nome!=null && this.clienteDTO.nome!=""){

      if(this.clienteDTO.id!=null){
        ///this.enderecoDTO.id         = this.enderecoDTO.id==null?null:this.enderecoDTO.id;
        this.enderecoDTO.clienteId     = this.clienteDTO.id;
        this.clienteDTO.endereco       = this.enderecoDTO;
        


        this.clienteDTO.contrato.dados = JSON.stringify({responsavel:this.responsavelDTO});
      }

     
      if(this.partnerDTO.id!=null){
        var temp = {...this.partnerDTO};
        temp.template = JSON.stringify(this.partnerDTO.template);
        this.clienteDTO.partner        = temp;
      }
      
      this.clienteService.clienteSave(this.clienteDTO);
    }
  }

  onUpdatesEndereco(){
    // if(this.clienteDTO.nome!=null && this.clienteDTO.nome!=""){
    //   //this.clienteService.clienteSave(this.clienteDTO);
    //   //this.salvarPedido();
    // }
  }

  onUpdatesResponsavel(){ //Form Responsavel
    if(this.clienteDTO.nome!=null && this.clienteDTO.nome!="" && this.responsavelDTO.email!=null && this.responsavelDTO.email!="" && this.responsavelDTO.nome!=null && this.responsavelDTO.nome!=""){
      if(this.responsavelDTO.email.indexOf('@')!=-1 && this.responsavelDTO.email.indexOf('.')!=-1){
         this.usuarioDTO.clienteId  = this.clienteDTO.id;
         this.usuarioDTO.nome       = this.responsavelDTO.nome;
         this.usuarioDTO.email      = this.responsavelDTO.email;
         this.usuarioDTO.enabled    = true; //Evita enviar email
    //////////     this.usuarioService.save(this.usuarioDTO,false);
         this.updateResponsavel.emit({responsavelDTO:this.responsavelDTO});


          /**
           * Grava responsavel no contrato
           */
          this.clienteDTO.contrato.dados = JSON.stringify({responsavel:this.responsavelDTO});
          this.clienteService.clienteSave(this.clienteDTO);

         this.salvarPedido();
      }
    }
  }





  onResponseClienteResponsavelSave(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseClienteResponsavelList(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseUsuarioSave(data: any): void {  
    // this.usuarioDTO = data;
   
   

    // if(this.clienteDTO.responsavel==null){
      
    //    this.clienteDTO.responsavel = {id:null, usuarioId: this.usuarioDTO.id};
    // //   this.debounceCliente.next(this.clienteDTO);

    // //   this.updateResponsavel.emit({responsavelDTO:this.responsavelDTO});
    // //   this.updateCliente.emit({clienteDTO:this.clienteDTO});

    // //   debugger
    //  } else {
    //   this.responsavelDTO.id = this.clienteDTO.responsavel.id;
    //   this.responsavelDTO.usuarioId = this.usuarioDTO.id;
    //   this.clienteDTO.responsavel = this.responsavelDTO;
      
    //  }

    //  this.clienteService.clienteSave(this.clienteDTO);
    //  localStorage.setItem('eplusclient', JSON.stringify({uuid:this.clienteDTO.clienteUUID,ordemid:this.pedidoDTO.id}));

    // this.responsavelDTO.usuarioId = this.usuarioDTO.id;
    // this.clienteDTO.responsavel = this.responsavelDTO;
    // this.clienteService.clienteSave(this.clienteDTO);

  }

  onResponseUsuarioFindById(data: any): void {
    // if(data!=null){
    //   this.usuarioDTO = data;

    //   this.responsavelDTO.nome = data.nome;
    //   this.responsavelDTO.email = data.email;

      
    //   this.updateResponsavel.emit({responsavelDTO:this.responsavelDTO});
    // }
  }

  onResponseUsuarioList(data: any): void {
    //throw new Error("Method not implemented.");
  }
  
  onResponseUsuarioListByClienteId(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseVerificarEmail(data: any): void {
    // console.log("onResponseVerificarEmail: " + JSON.stringify(data));

    // if(data!=null){
    //   this.usuarioDTO = data;

    //   this.responsavelDTO.nome = data.nome;
    //   this.responsavelDTO.email = data.email;

      
    //   this.updateResponsavel.emit({responsavelDTO:this.responsavelDTO});
    // }
 
  }

  onResponseBackofficeSave(data: any): void {
    // this.backofficeDTO=data;
    // this.alerta.open("Backoffice " + data.email + " salvo com sucesso.", "", {
    //   duration: 2000, 
    //   verticalPosition: 'top'
    // });
  }


  onResponseClienteSave(data: any): void {

    if(data!=null){
      this.clienteDTO = data;
      if(data.endereco!=null){
        this.enderecoDTO = data.endereco;
      }
    

      localStorage.setItem('eplusclient', JSON.stringify({uuid:this.clienteDTO.clienteUUID, ordemid: this.pedidoDTO.id}));
    

      //if(data.responsavel==null){
      //  this.clienteDTO.responsavel={};
      //}

      //localStorage.setItem('eplusclient', JSON.stringify({uuid:data.clienteUUID, email:this.responsavelDTO.email}));
      //localStorage.setItem('eplusclient', JSON.stringify({uuid:data.clienteUUID, ordemid:this.pedidoDTO.id}));

      this.salvarPedido();

      this.updateCliente.emit({clienteDTO:this.clienteDTO});
    }

    

    // this.alerta.open("Cliente " + data.nome + " salvo com sucesso.", "", {
    //   duration: 2000, 
    //   verticalPosition: 'top'
    // })

    // console.log("Cliente: " + JSON.stringify(this.clienteDTO));
  }

  onResponseClienteFindById(data: any): void {
    //throw new Error("Method not implemented.");
    // this.clienteDTO = data;
    // if(this.clienteDTO.endereco!=null){
    //   this.enderecoDTO = this.clienteDTO.endereco;
    // }else{
    //   this.clienteDTO.endereco={};
    // }
    // this.updateCliente.emit({clienteDTO:this.clienteDTO});
  }

  onResponseClienteFindByUUID(data: any): void {
  if(data!=null){
    this.clienteDTO = data;
    if(this.clienteDTO.endereco!=null){
      this.enderecoDTO = this.clienteDTO.endereco;
    }

    if(this.clienteDTO.contrato.dados!=null){
      this.responsavelDTO = JSON.parse(this.clienteDTO.contrato.dados).responsavel;
    }


    //if(this.clienteDTO.dados!=null && this.clienteDTO.dados.responsavel!=null){
    //  this.responsavelDTO = this.clienteDTO.dados.responsavel;
    //}
    // if(this.clienteDTO.responsavel!=null){
    //   this.responsavelDTO = this.clienteDTO.responsavel;
    // }

   /**
    * Pedidos do cliente
    */
   this.pedidoService.listByCliente(this.clienteDTO.id);




    this.updateCliente.emit({clienteDTO:this.clienteDTO});

  } else {
    this.pedidoDTO.dados.numeroPedido = 1;
    this.updatePedido.emit({pedidoDTO:this.pedidoDTO});
  }



    // if(this.clienteDTO.responsavel!=null){      
    //   if(localStorage.getItem('eplusclient')!=null){
    //     var email = JSON.parse(localStorage.getItem('eplusclient')).email;
    //     this.usuarioDTO.clienteId = this.clienteDTO.id;
    //     this.usuarioDTO.email = email;


    //     //this.usuarioService.findById(this.clienteDTO.responsavel.usuarioId);
    //     //console.log("usuarioDTO find email: " + JSON.stringify(this.usuarioDTO));
    //     //this.usuarioService.verificarEmail(this.usuarioDTO);
    //   }
    // }




  }


  onResponseClienteList(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onDownloadProgress(): void {
    //throw new Error("Method not implemented.");
  }
  onUploadProgress(): void {
    //throw new Error("Method not implemented.");
  }









  /***
   * Salvar rascunho do pedido Status 0
   */
  salvarPedido(){
    //Gerar novo pedido

    let pedidoDTOtemp = {...this.pedidoDTO};
    ///if(this.pedidoDTO.id==null){
      /**
       * Gera numero do pedido
       */
      // if(this.clienteDTO.contrato.numeroContrato==null){
      //   this.clienteDTO.contrato.numeroContrato = Math.floor(Math.pow(10, 9-1) + Math.random() * 9 * Math.pow(10, 9-1)).toString();
      // }
      // pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-" + (this.pedidosExistente.length + 1);
    
     if(pedidoDTOtemp.id==null){         
        pedidoDTOtemp.ordem=""; //So gera o numero na  confirmacao do pedido
     }


      pedidoDTOtemp.status=0;
    //}
    
    pedidoDTOtemp.clienteId = this.clienteDTO.id;
    pedidoDTOtemp.canalVenda = 1; //Emaisweb Site
    
    pedidoDTOtemp.dados.cliente = {id:this.clienteDTO.id, nome:this.clienteDTO.nome, contrato:this.clienteDTO.contrato.numeroContrato};
    pedidoDTOtemp.dados.responsavel = this.responsavelDTO;
    
    pedidoDTOtemp.dados = JSON.stringify(this.pedidoDTO.dados);
    this.pedidoService.save(pedidoDTOtemp);
  }




  onResponsePedidoSave(data: any): void {
    if(data!=null){      
      this.pedidoDTO.id         = data.id;
      this.pedidoDTO.clienteId  = data.clienteId;
      this.pedidoDTO.ordem      = data.ordem;
      this.pedidoDTO.data       = data.data;
      this.pedidoDTO.status     = data.status;
      this.pedidoDTO.dados      = JSON.parse(data.dados);  
      
      localStorage.setItem('eplusclient', JSON.stringify({uuid:this.clienteDTO.clienteUUID, ordemid: this.pedidoDTO.id}));
      //console.log("Pedido salvoxxx....: " + JSON.stringify(this.pedidoDTO));
    }    
  }

  onResponsePedidoFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponsePedidoList(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponsePedidoListByCliente(data: any): void {
    //throw new Error("Method not implemented.");
    if(data!=null){
      this.pedidosExistente = data;
    

      this.pedidosExistente.forEach((pedido:any,index:any) => {
        this.pedidosExistente[index].dados = JSON.parse(pedido.dados);

        //Recupera o pedido atual (Rascunho)
        if(JSON.parse(localStorage.getItem('eplusclient')).ordemid == pedido.id){
          this.pedidoDTO = this.pedidosExistente[index];
/////////////          this.responsavelDTO = this.pedidoDTO.dados.responsavel;
          this.updateResponsavel.emit({responsavelDTO:this.responsavelDTO});
          
        }
      });
    }
    this.pedidoDTO.dados.numeroPedido = this.pedidosExistente.length==0 ? 1 : this.pedidosExistente.length+1;
    this.updatePedido.emit({pedidoDTO:this.pedidoDTO});
    //console.log("Total de Pedidos: " + this.pedidosExistente.length);
    //console.log("Recuperado pedido atual: " + JSON.stringify(this.pedidoDTO));
  }

  onResponsePedidoListByStatus(data: any): void {

  }

  onResponsePedidoFindByOrdem(data: any): void {
    //throw new Error("Method not implemented.");
  }






}
