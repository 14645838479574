import { Component, OnInit, Input, Inject, ViewChild, ViewEncapsulation  } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

  @Component ({
    selector: 'eplus-partner-termo-uso-dialog',
    templateUrl: './eplus-partner-termo-uso-dialog.html',
    styleUrls: ['./eplus-partner-termo-uso-dialog.css'],
    encapsulation:ViewEncapsulation.None
  })
  export class EplusPartnerTermoUsoDialog implements OnInit {

    processandoShow=false;
    servicoLiberadoShow=false;
    conteudoLiberadoShow=false;
    pedidoAprovadoShow=false;

    constructor(public dialogRef: MatDialogRef<EplusPartnerTermoUsoDialog>, 
                @Inject(MAT_DIALOG_DATA) public data: any) {      
    }
  
    ngOnInit() {
      
    }


    cancelar(): void {
      this.dialogRef.close({cancelar: false});
    }
  
    confirmar(): void {

    }

    applyFilter(filterValue: string) {
    
    }
  }