import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewEncapsulation, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormControlDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter } from '@angular/material';
import { ActivatedRoute} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';



import { debounceTime, distinctUntilChanged, map, startWith, tap, switchMap, filter } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject'


import { IpedidoEvents } from '../../../../../marketplace/pedido/business/service/ipedido-events';
import { PedidoService } from '../../../../../marketplace/pedido/business/service/pedido.service';

import { ClienteService } from '../../../../../administration/cliente/business/service/cliente.service';
import { IclienteEvents } from '../../../../../administration/cliente/business/service/icliente-events';

import { UsuarioService } from '../../../../../administration/usuario/business/service/usuario.service';
import { IusuarioEvents } from '../../../../../administration/usuario/business/service/iusuario-events';

import { ClienteResponsavelService } from '../../../../../administration/cliente/business/service/responsavel/cliente.responsavel.service';
import { IclienteResponsavelEvents } from '../../../../../administration/cliente/business/service/responsavel/icliente-responsavel-events';

import { IpesquisaEvents } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/ipesquisa-events';
import { PesquisaService } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/pesquisa.service';


import { IavalicorpEvents } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/avalicorp/business/service/iavalicorp-events';
import { AvalicorpService } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/avalicorp/business/service/avalicorp.service';

import { CursoService } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/educorp/business/service/curso.service';
import { IcursoEvents } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/educorp/business/service/icurso-events';

import { EplusTermoUsoDialog } from './../termo-uso/eplus-termo-uso-dialog.component';
import { PedidoDaoService } from '../../../../../marketplace/pedido/business/dao/pedido-dao.service';
import { isNgTemplate } from '@angular/compiler';


// import { IpedidoEvents } from '../business/service/ipedido-events';
// import { PedidoService } from '../business/service/pedido.service';

// import { ClienteService } from '../../../administration/cliente/business/service/cliente.service';
// import { IclienteEvents } from '../../../administration/cliente/business/service/icliente-events';

// import { IpesquisaEvents } from '../../produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/ipesquisa-events';
// import { PesquisaService } from '../../produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/pesquisa.service';
// import { ServicoDaoService } from '../../../administration/servico/business/dao/servico-dao.service';


//eplus\eplusbackoffice\src\app\eplus\marketplace\pedido\application\component\catalogo\catalogo-produto.component.ts
// import { CatalogoProdutoComponent } from './component/catalogo/catalogo-produto.component';

@Component({
  selector: 'eplus-store-emaisweb-pedido',
  templateUrl: './store-emaisweb-pedido.html',
  styleUrls: ['./store-emaisweb-pedido.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip-custom-class-example.css` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
  
  providers: [PedidoService, ClienteService]   
})
export class StoreEmaiswebPedidoComponent implements  OnInit, 
                                                      IpedidoEvents,
                                                      IusuarioEvents,
                                                      IclienteEvents,
                                                      IpesquisaEvents,
                                                      IavalicorpEvents,
                                                      IcursoEvents {
  



   formGroup: FormGroup;

   cnpjmask = [/\d/, /\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/,/\d/,'/', /\d/, /\d/, /\d/, /\d/, '-',/\d/, /\d/];

   concordoTermoPrivacidade:boolean=false;

  //  @ViewChild(CatalogoProdutoComponent)
  //  private catalogoProdutoComponent: CatalogoProdutoComponent;


  //  private debouncePedido:Subject<any> = new Subject<any>();
  
  @Input()
  clienteDTO:any = {endereco:{}, 
                    responsavel:{}};

  @Input()
  responsavelDTO:any = {};

  @Input()
  pedidoDTO:any = {};

  usuarioDTO={
    id:null,
    clienteId:null,
    usuarioId:null,
    nome:"",
    email:"",
    enabled:false, //enviar email
    senha:null,
     roles: [
      {
        id: 1,
        nome: "ROLE_ADMIN",
        titulo: "Administrador",
        descricao: "Tem acesso a todos os serviços da plataforma como administrador."
      }
    ]               
  };
  

  backofficeDTO={clienteId:null,
    id:null,
    nome:"",
    email:"",
    senha:null,
    enabled:true, //Evita enviar email
    
     roles: [
      {
        id: 1,
        nome: "ROLE_ADMIN",
        titulo: "Administrador",
        descricao: "Tem acesso a todos os serviços da plataforma como administrador."
      }
    ]               
  };


  processandoShow=false;
  servicoLiberadoShow=false;
  conteudoLiberadoShow=false;
  pedidoAprovadoShow=false;
  pedidoProcessado=false;

  @Output() 
  updates = new EventEmitter();

  
  @Output() 
  removerItem = new EventEmitter();

 

  constructor(private alerta: MatSnackBar,
              private route: ActivatedRoute,
              private dateAdapter: DateAdapter<any>,
              private termoUsoDialog: MatDialog,
              private pedidoService: PedidoService,
              private usuarioService: UsuarioService,
              private clienteService: ClienteService,
              private pesquisaService: PesquisaService,
              private avalicorpService: AvalicorpService,
              private cursoService: CursoService
              ) { 

      pedidoService.eventSubscribe(this);
      usuarioService.eventSubscribe(this);
      clienteService.eventSubscribe(this);
      pesquisaService.eventSubscribe(this);
      avalicorpService.eventSubscribe(this);
      cursoService.eventSubscribe(this);

      this.dateAdapter.setLocale('pt-br');
      ///this => Esta prevalecendo ListClienteComponent
      
      
     
  }



  ngAfterViewInit(){
    //this.pedidoService.eventSubscribe(this);
       //* Verifica se cliente esta no browser */
       if(localStorage.getItem('eplusclient')!=null){
        //////var pedidoId = JSON.parse(localStorage.getItem('eplusclient')).ordemid;
        /////this.pedidoDTO.id = pedidoId;
      }
  }

  ngOnInit() {
    
    
    /**
     * Aguarda 3s apos onUpdates ser executado
     */
    //this.debouncePedido.pipe(debounceTime(3000)).subscribe(()=>{this.savePedido();});


    this.route.queryParams.subscribe(params => {
      if(params["canal"]!=null){
        //this.pedidoDTO = JSON.parse(params["pedido"]);
      }
      if(params["key"]!=null){ //Identifica a origem
        //this.pedidoDTO = JSON.parse(params["pedido"]);
      }
    });   
  }

  // public onEditPedido(pedidoDTO:any):void{
  //   debugger
  //   this.pedidoDTO = pedidoDTO; 
    
  //   this.clienteService.clienteFindById(this.pedidoDTO.clienteId);
  // }

  ngOnChanges(changes){
    //console.log("Maker change: " + JSON.stringify(changes));

    // debugger
    // this.clienteDTO.id = changes.pedidoDTO.currentValue.clienteId;
    // this.clienteService.clienteFindById(this.clienteDTO.id);
  }


  onUpdates() {
    //console.log("Updates...");
    
    /**
     * Save pedido
     */
    // this.debouncePedido.next(this.pedidoDTO);

  }


  onProdutos(event:any){
    //console.log("Produtos recebido do catalogo...");

  }

  lerTermoUso(){
    const dialogRef = this.termoUsoDialog.open(EplusTermoUsoDialog, {
      //disableClose:true,
      width: '70%',
      maxHeight: '600px'
      //,
      //data: {pedidoDTO: pedidoDTO}
    });
  }


  onRemoverItem(event:any){
    //console.log("event recebido carrinho: " + JSON.stringify(event));

    this.removerItem.emit(event);
  }


  //Retorna definicao de venda do produto no canal
  getCanalVenda(produtoDTO:any, canalVenda:number){
    let canalVendaResult={};

    if(produtoDTO.definicao.canalVendas!=null){
        canalVendaResult = produtoDTO.definicao.canalVendas.find((canal:any)=>{return canal.canalVenda==canalVenda;})
    }

    return canalVendaResult;
  }




  /*****
   * Save rascunho
   * Status 0:Rascunho
   */
  savePedido(){

      /**
       * Passo 1 - Gravar novo pedido
       */
      this.processandoShow=true;

      //Gerar novo pedido
      let pedidoDTOtemp = {...this.pedidoDTO};
      //if(this.pedidoDTO.id==null){
      /**
       * Gera numero do pedido
       */
      if(this.clienteDTO.contrato.numeroContrato==null){
        this.clienteDTO.contrato.numeroContrato = Math.floor(Math.pow(10, 9-1) + Math.random() * 9 * Math.pow(10, 9-1)).toString();
      }

//      if(pedidos!=null){
//        pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-" + (pedidos.length + 1);
//      } else {
        if(pedidoDTOtemp.dados.numeroPedido==null){
          pedidoDTOtemp.dados.numeroPedido=1;
        }
        pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-" + pedidoDTOtemp.dados.numeroPedido;
//      }


      ///pedidoDTOtemp.status=2; //Verificar se é contrato ou demo..... 2 - aprovado para demo e 1 - para aprovar pagamento

      /**
       * Verificar se é contrato ou demo..... 2 - aprovado para demo e 1 - para aprovar pagamento
       */
      //var temp:any = pedidoDTOtemp.dados.itens.filter((item:any)=>{return item.tipoItem==1 && (item.produto.definicao.demo!=null && item.produto.definicao.demo==true);});
      //     let foundServico = this.clienteDTO.contrato.servicos.filter(function(servico){return  servico.id == item.id && item.servicoId==null && item.tipo==1} )

      let itensProdutoDemo:[] = pedidoDTOtemp.dados.itens.filter(function(item:any){return item.tipoItem==1 && (item.produto.definicao.demo!=null && item.produto.definicao.demo==true);});
      
      if(itensProdutoDemo.length!=0){
        pedidoDTOtemp.status=1; 

        itensProdutoDemo.forEach((item:any)=>{
          if(item.produto.definicao.demo){
            pedidoDTOtemp.demo=true;
            pedidoDTOtemp.diasDemo = item.produto.definicao.diasDemo;
          }
        });

        //console.log("Pedido com item DEMO!!!!!!!");
      } else {
        pedidoDTOtemp.status=1;
        pedidoDTOtemp.demo=false;
        pedidoDTOtemp.diasDemo = 0;

        //console.log("Pedido com item sem DEMO!!!!!!!"); 
      }




      //}
    
    pedidoDTOtemp.clienteId = this.clienteDTO.id;
    pedidoDTOtemp.canalVenda = 1; //Emaisweb Site
    
    pedidoDTOtemp.dados.cliente = {id:this.clienteDTO.id, nome:this.clienteDTO.nome, contrato:this.clienteDTO.contrato.numeroContrato};
///////////////    pedidoDTOtemp.dados.responsavel = this.responsavelDTO;
    
    pedidoDTOtemp.dados = JSON.stringify(this.pedidoDTO.dados);
    this.pedidoService.save(pedidoDTOtemp);

    











    //Gerar novo pedido
    //let pedidoDTOtemp = {...this.pedidoDTO};
    ///if(this.pedidoDTO.id==null){
      /**
       * Gera numero do pedido
       */
      //if(this.clienteDTO.contrato.numeroContrato==null){
      //  this.clienteDTO.contrato.numeroContrato = Math.floor(Math.pow(10, 9-1) + Math.random() * 9 * Math.pow(10, 9-1)).toString();
      //}

      //if(pedidos!=null){
      //  pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-" + (pedidos.length + 1);
      //} else {
      //  pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-1";
      //}


    //  pedidoDTOtemp.status=2;
    //}
    
    //pedidoDTOtemp.clienteId = this.clienteDTO.id;
    //pedidoDTOtemp.canalVenda = 1; //Emaisweb Site
    
    //pedidoDTOtemp.dados.cliente = {id:this.clienteDTO.id, nome:this.clienteDTO.nome, contrato:this.clienteDTO.contrato.numeroContrato};
    //pedidoDTOtemp.dados.responsavel = this.responsavelDTO;
    
    //pedidoDTOtemp.dados = JSON.stringify(this.pedidoDTO.dados);
    //this.pedidoService.save(pedidoDTOtemp);








    //***** Pesquisa se cliente ja tem pedidos */
    //this.pedidoService.listByCliente(this.clienteDTO.id);





    // let pedidoDTOtemp = {...this.pedidoDTO};
    // if(this.pedidoDTO.id==null){
    //   /**
    //    * Gera numero do pedido
    //    */
    //   if(this.clienteDTO.contrato.numeroContrato==null){
    //     this.clienteDTO.contrato.numeroContrato = Math.floor(Math.pow(10, 9-1) + Math.random() * 9 * Math.pow(10, 9-1)).toString();
    //   }

    //   if(this.clienteDTO.contrato.pedidos!=null){
    //     pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-" + (JSON.parse(this.clienteDTO.contrato.pedidos).length + 1);
    //   } else {
    //     pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-1";
    //   }


    //   pedidoDTOtemp.status=2;
    // }
    
    // pedidoDTOtemp.clienteId = this.clienteDTO.id;
    // pedidoDTOtemp.canalVenda = 1; //Emaisweb Site
    // pedidoDTOtemp.dados.cliente = {id:this.clienteDTO.id, nome:this.clienteDTO.nome, contrato:this.clienteDTO.contrato.numeroContrato};
    // pedidoDTOtemp.dados = JSON.stringify(this.pedidoDTO.dados);
    // this.pedidoService.save(pedidoDTOtemp);
  }


  onResponsePedidoSave(data: any): void {
    //throw new Error("Method not implemented.");
    if(data!=null){      
      this.pedidoDTO.id         = data.id;
      this.pedidoDTO.clienteId  = data.clienteId;
      this.pedidoDTO.ordem      = data.ordem;
      this.pedidoDTO.data       = data.data;
      this.pedidoDTO.status     = data.status;
      this.pedidoDTO.dados      = JSON.parse(data.dados);



      /**
       * Passo 2 - Gravar usuario(Responsavel) e enviar email com senha 
       */
//////      this.usuarioDTO.clienteId = this.pedidoDTO.clienteId;
//////      this.usuarioDTO.email = this.pedidoDTO.dados.responsavel.email;
//////      this.usuarioDTO.nome = this.pedidoDTO.dados.responsavel.nome;
//////      this.usuarioService.save(this.usuarioDTO, false);
  
      this.usuarioDTO.clienteId = this.clienteDTO.id;
      this.usuarioDTO.email     = JSON.parse(this.clienteDTO.contrato.dados).responsavel.email;
      this.usuarioDTO.nome      = JSON.parse(this.clienteDTO.contrato.dados).responsavel.nome;
      this.usuarioService.save(this.usuarioDTO, false);




    }
  }
  onResponsePedidoFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponsePedidoList(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponsePedidoListByCliente(data: any): void {
    // //throw new Error("Method not implemented.");
    // console.log("Gerar pedido.....!xxxx!!!!!!!");
    // //Lista de pedidos que o cliente ja tem
    // let pedidos = data;
    // pedidos.forEach((pedido:any,index:any) => {
    //   pedidos[index].dados = JSON.parse(pedido.dados);
    // });
    // console.log("Pedidosxxxx: " + pedidos.length);




    // //Gerar novo pedido
    // let pedidoDTOtemp = {...this.pedidoDTO};
    // //if(this.pedidoDTO.id==null){
    //   /**
    //    * Gera numero do pedido
    //    */
    //   if(this.clienteDTO.contrato.numeroContrato==null){
    //     this.clienteDTO.contrato.numeroContrato = Math.floor(Math.pow(10, 9-1) + Math.random() * 9 * Math.pow(10, 9-1)).toString();
    //   }

    //   if(pedidos!=null){
    //     pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-" + (pedidos.length + 1);
    //   } else {
    //     pedidoDTOtemp.ordem = this.clienteDTO.contrato.numeroContrato + "-1";
    //   }






    //   /**
    //    * Verificar se é contrato ou demo..... 2 - aprovado para demo e 1 - para aprovar pagamento
    //    */
    //   if(pedidoDTOtemp.dados.itens.filter((item:any)=>{return item.tipoItem==1 && (item.produto.definicao.demo!=null && item.produto.definicao.demo==true);})==null){
    //     pedidoDTOtemp.status=2; 
    //     console.log("Pedido com item DEMO!!!!!!!");
    //   } else {
    //     pedidoDTOtemp.status=1;
    //     console.log("Pedido com item sem DEMO!!!!!!!"); 
    //   }
    
    // //}
    
    // pedidoDTOtemp.clienteId = this.clienteDTO.id;
    // pedidoDTOtemp.canalVenda = 1; //Emaisweb Site
    
    // pedidoDTOtemp.dados.cliente = {id:this.clienteDTO.id, nome:this.clienteDTO.nome, contrato:this.clienteDTO.contrato.numeroContrato};
    // pedidoDTOtemp.dados.responsavel = this.responsavelDTO;
    
    // pedidoDTOtemp.dados = JSON.stringify(this.pedidoDTO.dados);
    // this.pedidoService.save(pedidoDTOtemp);

  }


  onResponsePedidoListByStatus(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponsePedidoFindByOrdem(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onDownloadProgress(): void {
    //throw new Error("Method not implemented.");
  }
  onUploadProgress(): void {
    //throw new Error("Method not implemented.");
  }



  //******************************************************
  //* Responses IusuarioEvents
  //******************************************************
  onResponseUsuarioSave(data: any): void {
    //throw new Error("Method not implemented.");
    //console.log("Usuario salvo com sucesso....: " + JSON.stringify(data));

    /**
     * Passo 3 - Gravar usuario como responsavel do cliente
     */    
    //Disponibilizar serviço na plataforma
    this.pedidoDTO.dados.itens.forEach((item:any)=>{
        if(item.tipoItem==1){ //Produto
          item.produto.definicao.servicos.forEach((servico:any) => {
            //console.log("Disponibilizar: " + servico.servico);
            if(servico.tipo==1 && servico.contratar==true){ //Microservice (Recorrente)
              this.clienteDTO.contrato.servicos.push({id:servico.id, name:servico.servico});
            }
          });
        }      
    });

    this.clienteDTO.responsavel = {id:null, usuarioId: data.id};   
    this.clienteDTO.ativo = true; //!!!!! verificar se pedido é demo
    this.clienteService.clienteSave(this.clienteDTO);




    //Grava usuario backoffice
    if(this.clienteDTO.contrato!=null){
      this.backofficeDTO.clienteId = this.clienteDTO.id;
      this.backofficeDTO.nome  = "backoffce." + this.clienteDTO.contrato.numeroContrato;
      this.backofficeDTO.email = "backoffice." + this.clienteDTO.contrato.numeroContrato + "@emaisweb.com";
      this.backofficeDTO.senha = "123456";
      this.backofficeDTO.enabled = true;
      this.usuarioService.save(this.backofficeDTO,true);
    }


  }

  onResponseBackofficeSave(data: any): void {
    //throw new Error("Method not implemented.");
    this.backofficeDTO = data;
  }

  onResponseUsuarioFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseVerificarEmail(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseUsuarioList(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseUsuarioListByClienteId(data: any): void {
    //throw new Error("Method not implemented.");
  }




  //******************************************************
  //* Responses IclienteEvents
  //******************************************************
  onResponseClienteSave(data: any): void {
    //throw new Error("Method not implemented.");
    this.servicoLiberadoShow=true;
    //console.log("Cliente, responsavel e servicos salvo.....: " + JSON.stringify(data));
    debugger
    
    /**
     * Passo 4 - Liberar conteudos
     */    
      //Clonar conteudos
      this.pedidoDTO.dados.itens.forEach((item:any)=>{
        if(item.tipoItem==1){ //Produto
          item.produto.definicao.servicos.forEach((servico:any) => {
            //console.log("Disponibilizar conteudo do servico: " + servico.servico);
            if(servico.tipo==1 && servico.id==1){ //Survey
              servico.conteudos.forEach((conteudo:any)=>{
                this.processandoShow=true;
                this.pesquisaService.eventSubscribe(this);
                this.pesquisaService.clonePesquisa(this.clienteDTO.id, conteudo.id);
              });
              //this.clienteDTO.contrato.servicos.push({id:servico.id, name:servico.servico});
            }

            if(servico.tipo==1 && servico.id==2){ //educorp
              servico.conteudos.forEach((conteudo:any)=>{
                this.processandoShow=true;
                this.cursoService.eventSubscribe(this);
                this.cursoService.cloneCursoToCliente(this.clienteDTO.id, conteudo.id);
              });
              //this.clienteDTO.contrato.servicos.push({id:servico.id, name:servico.servico});
            }


            if(servico.tipo==1 && servico.id==9){ //Avalicorp
              servico.conteudos.forEach((conteudo:any)=>{
                this.processandoShow=true;
                this.avalicorpService.eventSubscribe(this);
                this.avalicorpService.cloneAvalicorp(this.clienteDTO.id, conteudo.id);
              });
              //this.clienteDTO.contrato.servicos.push({id:servico.id, name:servico.servico});
            }

          });
        }
        
        
        if(item.tipoItem==3){ //Conteudo
          if(item.conteudo.tipo==1 && item.conteudo.servicoId==1){ //Survey
            
              this.processandoShow=true;
              this.pesquisaService.eventSubscribe(this);
              this.pesquisaService.clonePesquisa(this.clienteDTO.id, item.conteudo.id);
           
            //this.clienteDTO.contrato.servicos.push({id:servico.id, name:servico.servico});
          }

          if(item.conteudo.tipo==1 && item.conteudo.servicoId==2){ //Educorp
            this.processandoShow=true;
            this.cursoService.eventSubscribe(this);
            this.cursoService.cloneCursoToCliente(this.clienteDTO.id, item.conteudo.id);
          }

          if(item.conteudo.tipo==1 && item.conteudo.servicoId==9){ //Avalicorp
            
            this.processandoShow=true;
            this.avalicorpService.eventSubscribe(this);
            this.avalicorpService.cloneAvalicorp(this.clienteDTO.id, item.conteudo.id);
         
          //this.clienteDTO.contrato.servicos.push({id:servico.id, name:servico.servico});
          }

        }


      });
  }

  onResponseClienteFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseClienteFindByUUID(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseClienteList(data: any): void {
    //throw new Error("Method not implemented.");
  }


  //******************************************************
  //* Responses IclienteEvents
  //******************************************************
  onResponsePesquisaFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponsePesquisaListByClienteId(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseClonePesquisa(data: any): void {
    //throw new Error("Method not implemented.");
    //console.log("Conteudo da pesquisa clonado: " + JSON.stringify(data));
    this.conteudoLiberadoShow=true;
    //this.processandoShow=false;    
  }

  onResponseAvalicorpFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponseAvalicorpListByClienteId(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponseCloneAvalicorp(data: any): void {
    //throw new Error("Method not implemented.");
    this.conteudoLiberadoShow=true;
  }



  onResponseCursoFindById(data: any): void {
    //throw new Error('Method not implemented.');
  }
  onResponseCursoListByClienteId(data: any): void {
    //throw new Error('Method not implemented.');
  }
  onResponseCloneCursoToCliente(data: any): void {
    //throw new Error('Method not implemented.');
    debugger
  }
  onResponseDecryptPost(data: any): void {
    //throw new Error('Method not implemented.');
  }
  onResponseDecrypt(data: any): void {
    //throw new Error('Method not implemented.');
  }


}
