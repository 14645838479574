import { Component, OnInit, ViewChild, Input, Output,  EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { MatSnackBar } from '@angular/material';

import { ProdutoService } from '../../../../../marketplace/produto/business/service/produto.service';
import { IprodutoEvents } from '../../../../../marketplace/produto/business/service/iproduto-events';



/**
 * @title Table with filtering
 */
@Component({
  selector: 'eplus-store-emaisweb-catalog-detail',
  styleUrls: ['store-emaisweb-catalog-detail.component.css'],
  templateUrl: 'store-emaisweb-catalog-detail.component.html',
})
export class StoreEmaiswebCatalogDetailComponent implements OnInit, IprodutoEvents {
 
  //@Output() 
  //clienteSelecionado = new EventEmitter();

  @Input() 
  pedidoDTO:any; 

  @Output() 
  produtoEvent = new EventEmitter();

 
  ///@Input() myFunction: Function;

  produtoList:any = [];
  produtoSelecionados:any=[];


  //Valor unitário por usuario
  qtdUsuario=10;
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }


 
  constructor(private alerta: MatSnackBar,
              private produtoService: ProdutoService) {     
    produtoService.eventSubscribe(this);
  }

  ngOnInit() {
    this.produtoService.listByCanalVenda(1); //site


    
  }


 
  onUpdate(){
   
  }

  ngOnChanges(changes){
    //console.log("Maker change: " + JSON.stringify(changes));
  }


 

  selecionaProduto(produto:any){
    //console.log(" produtoDTO: " + JSON.stringify(produto) );


    this.produtoEvent.emit({produtoDTO: produto});

  }

  //Chamado por MakerPedidoComponent 
  desmarcarProduto(produtoId:number){
    


    let index = this.produtoSelecionados.findIndex((produto:any)=>{return produto.id==produtoId;});
    console.log("Index:" + index);
    if(index!=-1){
      //console.log("desmarcarProduto............" + produtoId);
      this.produtoSelecionados.splice(index,1);
      this.produtoList.find((produto:any)=>{return produto.id == produtoId;}).checked=false;
    }
  }

  getCanalVenda(produtoDTO:any, canalVenda:number){
    let canalVendaResult={};

    if(produtoDTO.definicao.canalVendas!=null){
        canalVendaResult = produtoDTO.definicao.canalVendas.find((canal:any)=>{return canal.canalVenda==canalVenda;})
    }

    return canalVendaResult;
  }


  changeRecorrenteNaoRecorrente(produtoDTO:any, index:number){
    let definicaoProdutoBackoffice = produtoDTO.definicao.canalVendas.find((definicaoCanal:any)=>{return definicaoCanal.canalVenda==4});

    if(definicaoProdutoBackoffice.tipoVenda==1){
      //console.log("Nao recorrente");
      //produtoDTO.definicao.canalVendas.find((definicaoCanal:any)=>{return definicaoCanal.canalVenda==4}).recorrente=false;

      this.produtoList[index].definicao.canalVendas.find((definicaoCanal:any)=>{return definicaoCanal.canalVenda==4}).recorrente=false;
    }
    if(definicaoProdutoBackoffice.tipoVenda==2 || definicaoProdutoBackoffice.tipoVenda==3){
      //console.log("recorrente...");
      //produtoDTO.definicao.canalVendas.find((definicaoCanal:any)=>{return definicaoCanal.canalVenda==4}).recorrente=true;
      this.produtoList[index].definicao.canalVendas.find((definicaoCanal:any)=>{return definicaoCanal.canalVenda==4}).recorrente=true;

    }
  }

  onResponseProdutoSave(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponseProdutoFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponseProdutoList(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onResponseProdutoListByCanalVenda(data: any): void {
    //throw new Error("Method not implemented.");
    //console.log("produtoList: " + JSON.stringify(data));

    
    data.forEach((produto:any, index:number)=>{
       //   let itemServicoIndex = pedidoDTOtemp.dados.itens.findIndex((item:any)=>{return item.id==servico.id && item.serviceId==null;});

       //Seleciona produtos em funcao do item(produto) do pedido
      let itemIndex = this.pedidoDTO.dados.itens.findIndex((item:any)=>{return item.tipoItem==1 && item.id==produto.id;})
      if(itemIndex!=-1){
        data[index].checked = true;
        this.produtoSelecionados.push(produto);
      }
      //String to Object(json)
      data[index].definicao = JSON.parse(produto.definicao);
    });

    this.produtoList = data;


  }
  onResponseProdutoUpload(data: any): void {
    //throw new Error("Method not implemented.");
  }
  onDownloadProgress(): void {
    //throw new Error("Method not implemented.");
  }
  onUploadProgress(): void {
    //throw new Error("Method not implemented.");
  }




}