import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';

import { IpartnerEvents } from '../service/ipartner-events';

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { IpartnerService } from './ipartner-service';

import { PartnerDaoService } from '../dao/partner-dao.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService implements IpartnerService {

  private subject = new Subject<any>();

  private _super:IpartnerEvents;

  constructor(private partnerDaoService: PartnerDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IpartnerEvents){
      this._super = _super;
    }


   /**
     * 
     */
    save(partnerDTO):void{

     
      this.partnerDaoService.save(partnerDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePartnerSave(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    
    findById(partnerId: any): void {
      
      this.partnerDaoService.findById(partnerId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));                  
                    this._super.onResponsePartnerFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    findByUUID(uuid: any): void {
      this.partnerDaoService.findByUUID(uuid)
      .subscribe(event=>{
        switch (event.type) {
          case 'start':
            //console.log("request...");
            break;

          case 'response':
            //this.produtoList = event.data;
            //console.log("Resposta:  " + JSON.stringify(event.data));
            this._super.onResponsePartnerFindByUUID(event.data);
            break;
            
          case 'downloadProgress':
            this._super.onDownloadProgress();
            //console.log(`Download in progress! ${ event.data }Kb loaded`);
            break;
        }
      }, 
       error => console.log("error" + error)
    );

    }
 
 
    findByDominio(dominio: any): void {
      this.partnerDaoService.findByDominio(dominio)
      .subscribe(event=>{
        switch (event.type) {
          case 'start':
            //console.log("request...");
            break;

          case 'response':
            //this.produtoList = event.data;
            //console.log("Resposta:  " + JSON.stringify(event.data));
            this._super.onResponsePartnerFindByDominio(event.data);
            break;
            
          case 'downloadProgress':
            this._super.onDownloadProgress();
            //console.log(`Download in progress! ${ event.data }Kb loaded`);
            break;
        }
      }, 
       error => console.log("error" + error)
    );

    }





    /**
     * 
     */
    list():void{
      this.partnerDaoService.list()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePartnerList(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
    


    uploadS3(partnerDTO: any, file: any): void {
      this.partnerDaoService.uploadS3(partnerDTO, file)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponsePartnerUpload(event.data);
                    break;
                  case 'uploadProgress':
                    this._super.onUploadProgress();
                    //console.log(`Upload in progress! ${ event.data }Kb loaded`);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    ///console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
  
}