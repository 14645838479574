import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';

import { environment } from '../../../../../../../../../../../../../environments/environment';

import { IpesquisaDao } from './ipesquisa-dao';

@Injectable({
  providedIn: 'root'
})
export class PesquisaDaoService implements IpesquisaDao {


  ///EPLUS_MARKETPLACE_URL: string = "http://localhost:8080/eplusservicemarketplace/administration/produto/list/";
 private EPLUS_MARKETPLACE_URL = `${environment.gatewayApi}/survey/administracao/pesquisa`;
 //private EPLUS_MARKETPLACE_URL = 'https://api.emaisweb.com/eplusgateway/survey/administracao/pesquisa';




  constructor(private http: HttpClient) { 

  }


  findById(clienteId:any, pesquisaId: any): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/client/' + clienteId + "/pesquisa/" + pesquisaId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }

  /**
   * Lista todos os produtos
   */
  listByClienteId(clienteId:any): Observable<any>{
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/list/client/' + clienteId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            //console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            ///console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })
  }


  clonePesquisa(clienteId: any, pesquisaId: any): Observable<any> {
    return new Observable((observe)=>{

      this.http.get(this.EPLUS_MARKETPLACE_URL + '/clone/client/' + clienteId + "/survey/" + pesquisaId,{observe: 'events', reportProgress: true})
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            
            //console.log('Request sent!');
            observe.next({type:'start', data:null});
            break;
          case HttpEventType.ResponseHeader:
            debugger
            console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            //console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            //this._super.onProgress();
            observe.next({type:'downloadProgress', data: kbLoaded});
            break;
          case HttpEventType.Response:
            debugger
            //console.log('😺 Done!', event.body);
            observe.next({type:'response', data:event.body});
            observe.complete();
            //res = event.body;
            //return new Observable(event.body);
            //console.log("res:   " + res);
            break;
        }
      });
    })

  }




}
