import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../services/administration/user/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
   
    const authToken = this.authService.getToken();

    let headers = new HttpHeaders({ 'Authorization': `Bearer${authToken}` });

    if (!(req.body instanceof FormData)){ //Trata upload`s
      headers = headers.set("Content-type", "application/json; charset=UTF-8")
    }


    const authReq = req.clone({
      headers: headers
    });

    return next.handle(authToken ? authReq : req)
      .pipe(tap(event => { },
        error => {
          
          if (error instanceof HttpErrorResponse)
            if (error.status === 401) {
              this.router.navigate(['/login']);
              return of(error);
            }
        }
      ));

  }

}