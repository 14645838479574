import { Component, OnInit, ViewChild, Input, Output,Inject,  EventEmitter, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {FormControl} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';




//import { ProdutoService }  from '../../../../produto/business/service/produto.service';
//import { IprodutoEvents }  from '../../../../produto/business/service/iproduto-events';

import { IservicoEvents } from '../../../../../administration/servico/business/service/iservico-events';
import { ServicoService } from '../../../../../administration/servico/business/service/servico.service';
import { ListClienteComponent } from '../../../../../administration/cliente/application/list/list.cliente.component';

import { PesquisaService } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/pesquisa.service';
import { IpesquisaEvents } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/survey/business/service/ipesquisa-events';

import { CursoService } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/educorp/business/service/curso.service';
import { IcursoEvents } from '../../../../../marketplace/produto/application/component/maker-produto/servico/tipo/microservice/educorp/business/service/icurso-events';


import { ViewEncapsulation } from '@angular/compiler/src/core';


/**
 * @title Table with filtering
 */
@Component ({
  selector: 'eplus-store-partner-carrinho',
  styleUrls: ['store-partner-carrinho.component.css'],
  templateUrl: 'store-partner-carrinho.component.html',
  //encapsulation: ViewEncapsulation.None 
})
export class StorePartnerCarrinhoComponent implements OnInit, IservicoEvents,IpesquisaEvents, IcursoEvents {

 
  //@Input() 
  //pedidoDTO:any;

  @Output() 
  itensEvent = new EventEmitter();

  @Output() 
  removerItem = new EventEmitter();

  servicoList:any=[];
  servicoFiltros:any=[];
  servicoCtrl = new FormControl();
  filteredServicos: Observable<any[]>;


  conteudoList:any=[];
  conteudoFiltros:any=[];
  conteudoCtrl = new FormControl();
  filteredConteudos: Observable<any[]>;

  progressServicoShow  = false;
  progressConteudoShow = false;

  pedidoRecorrente = false; //So pode ter item recorrente ou so pode ter item (a vista ou parcelado)

  @Input()
  pedidoDTO:any;

  //@Input()
  itens:any = [];
  itemIndex:number = -1; 
 
  servicoDTO:any = {id:null, name:''};
  conteudoDTO:any = {id:null, name:''};

  constructor(private alerta: MatSnackBar, 
              public dialog: MatDialog,
              private servicoService: ServicoService,
              private pesquisaService: PesquisaService,
              private cursoService: CursoService) { 
    
    servicoService.eventSubscribe(this); 
    pesquisaService.eventSubscribe(this);
    cursoService.eventSubscribe(this);

    // this.filteredServicos = this.servicoCtrl.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(servico => servico ? this._filterServicos(servico) : this.servicoList.slice())
    //   );


  }

  ngOnInit() {

    this.itens=this.pedidoDTO.dados.itens;


    this.progressServicoShow=true;
    this.servicoService.list();
  }

  onUpdates(){

  }


  private _filterServicos(value: any): any[] {
   if(typeof value == 'object') {
     return [];
   } else {
    console.log("_filter: " + value);
    const filterValue = value.toLowerCase();

    return this.servicoList.filter(servico => servico.name.toLowerCase().indexOf(filterValue) === 0);
   }
  }

  private _filterConteudos(value: any): any[] {
    if(typeof value == 'object') {
      return [];
    } else {
     console.log("_filter: " + value);
     const filterValue = value.toLowerCase();
 
     return this.conteudoList.filter(conteudo => conteudo.name.toLowerCase().indexOf(filterValue) === 0);
    }
   }
 


  displayServico(servico:any) {
    if(servico!=null){
      return servico.name;
    }else{
      return '';
    }
  }

  displayConteudo(conteudo:any) {
    if(conteudo!=null){
      return conteudo.name;
    }else{
      return '';
    }
  }



  servicoSelecionado(event:any){
    this.servicoDTO = event;
    //console.log("Selecionado: " + JSON.stringify(event));
   

    if(this.servicoDTO.id == 1 && this.servicoDTO.tipo==1) { //SURVEY !!!! no lugar de id o ideal seia o campo identification da tabela tb_service
      this.progressConteudoShow=true;
      this.pesquisaService.listByClienteId(1); //1 ===>> emaisweb
    }

    if(this.servicoDTO.id == 2 && this.servicoDTO.tipo==1) {
      this.progressConteudoShow=true;
      this.cursoService.listByClienteId(1); //1 =====> emaisweb
    }
  }

  conteudoSelecionado(event:any){
    this.conteudoDTO = event;
    //console.log("conteudo sel...." + JSON.stringify(event));
  }


  adicionar(){

   /**
    * Analisar itens Recorrentes e A vista ou parcelado
    */
   //debugger
    let analiseOk:boolean;

    if(this.itens.length == 0){ //Não tem item no pedido
      if(this.servicoDTO.tipo==1){ //Microservico(Recorrente)
        this.pedidoDTO.dados.recorrente = true;
      } else {
        this.pedidoDTO.dados.recorrente = false;
      }

      this.adicionaServicoOuConteudo();
    } else {
        this.itens.forEach((item:any)=>{
            if(item.tipoItem==1){ //Produto
              let definicaoProdutoBackoffice = item.produto.definicao.canalVendas.find((definicaoCanal:any)=>{return definicaoCanal.canalVenda==4});

              if(this.servicoDTO.tipo!=1){ //Diferente de microservico(Recorrente)
                if(definicaoProdutoBackoffice.recorrente==true){
                  this.alerta.open("Servico não pode ser adicionado, já existe um ou mais item no pedido que é recorrente.", "Ok", {
                    duration: 0, 
                    verticalPosition: 'top',
                    panelClass: ['itemExistenteAlerta']        
                  });

                  analiseOk = false;
                  return false; //break
                }
              } else {
                analiseOk = true;
              }
            }

            if(item.tipoItem==2){ //Servico
              if(this.servicoDTO.tipo!=1 && item.servico.tipo==1){
                this.alerta.open("Servico não pode ser adicionado, já existe um ou mais item no pedido que é recorrente.", "Ok", {
                  duration: 0, 
                  verticalPosition: 'top',
                  panelClass: ['itemExistenteAlerta']        
                });    
                analiseOk=false;           
                return false; //break
              } else {
                analiseOk = true;
              }

              if(this.servicoDTO.tipo==1 && item.servico.tipo!=1){
                this.alerta.open("Servico não pode ser adicionado, já existe um ou mais item no pedido que não é recorrente.", "Ok", {
                  duration: 0, 
                  verticalPosition: 'top',
                  panelClass: ['itemExistenteAlerta']        
                }); 
                analiseOk =  false;             
                return false; //break
              } else {
                analiseOk = true;
              }

            }

            if(item.tipoItem==3){ //Conteudo

            }      
          });


          /**
           * Adiciona Servico ou Conteudo
           */
          if(analiseOk==true){
            this.adicionaServicoOuConteudo();
          }
    } 
  }


  private adicionaServicoOuConteudo(){
    //console.log("Adiciona servico ou conteudo.....");

    if(this.servicoDTO.id!=null && this.conteudoDTO.id==null){ //Add Servico


      // const dialogRef = this.dialog.open(ItemExistenteDialog,{
      //   width: '600px',
      //   height: '400px',
      //   data:{}
      // });
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log(`Dialog result: ${result}`);
      // });

      // this.alerta.open("Item já foi adicionado.", "Ok", {
      //   duration: 0, 
      //   verticalPosition: 'top',
      //   panelClass: ['itemExistenteAlerta']        
      // });


      
      //console.log("add servico ao pedido..." + JSON.stringify(this.servicoDTO));

      this.itens.push({tipoItem:2, id:this.servicoDTO.id, servico:this.servicoDTO});

      this.itensEvent.emit({itens:this.itens, addItem:this.servicoDTO});

      // let foundServico = this.itens.filter((iten)=>{return (iten.id == this.servicoDTO.id) && (iten.servicoId==null) && (iten.tipo==1);} )
      // if(foundServico==false){
      //   this.itens.push(this.servicoDTO);
      //   this.itensEvent.emit({itens:this.itens});
      //   this.servicoDTO = {id:null, name:''};
      //   this.conteudoDTO = {id:null, name:''};
      //   this.servicoCtrl.reset();
      //   this.conteudoCtrl.reset();
      // }else{
      //   this.alerta.open("Serviço " + this.servicoDTO.name + " já foi adicionado.", "Ok", {
      //     duration: 5000, 
      //     verticalPosition: 'top'
      //   });
      // }
  
    } else if (this.servicoDTO.id!=null && this.conteudoDTO.id!=null){ // Add conteudo

      //console.log("add conteudo ao pedido..." + JSON.stringify(this.conteudoDTO));
      this.itens.push({tipoItem:3, id:this.conteudoDTO.id, conteudo:this.conteudoDTO});

      this.itensEvent.emit({itens:this.itens, addItem:this.conteudoDTO});

      // let foundConteudo =this.itens.filter((iten)=>{return  iten.servicoId == this.servicoDTO.id && iten.id == this.conteudoDTO.id;} )
      // if(foundConteudo==false){
      //   this.itens.push(this.conteudoDTO);
      //   this.itensEvent.emit({itens:this.itens});       
      //   this.conteudoDTO = {id:null, name:''};
      //   this.conteudoCtrl.reset();
      // }else{
      //   this.alerta.open("Conteudo " + this.conteudoDTO.name + " já foi adicionado.", "Ok", {
      //     duration: 5000, 
      //     verticalPosition: 'top'
      //   });
      // }
  
    }


  }



  removeItem(itemIndex:number) {

    //console.log("Remover item: " + itemIndex);

    this.pedidoDTO.dados.itens.splice(itemIndex,1);

    //let item = this.itens[itemIndex];
    //this.itens.splice(itemIndex,1);
    this.removerItem.emit({itemIndex:itemIndex});
  }


  confirmarPedido(){
    //console.log("confirmar pedido...");
  }

  onResponseServicoSave(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseServicoFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseServicoList(data: any): void {
    //throw new Error("Method not implemented.");
    this.servicoList  = data;


    this.servicoFiltros = this.servicoList;
    //this.servicoFiltros = this.servicoList.filter(servico => servico.name.toLowerCase().indexOf("i") > -1);
    this.filteredServicos = this.servicoCtrl.valueChanges
      .pipe(
        startWith(''),
        map(servico => servico ? this._filterServicos(servico) : this.servicoList.slice())
      );

    this.progressServicoShow=false;
    //console.log("ServicoList: " + JSON.stringify(data));
  }

  onDownloadProgress(): void {
    //throw new Error("Method not implemented.");
  }
  onUploadProgress(): void {
    //throw new Error("Method not implemented.");
  }


  onResponseCursoFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponseCursoListByClienteId(data: any): void {

    this.conteudoList=[];
    data.forEach(curso=>{
      this.conteudoList.push({
        servicoId:2,
        tipo:1,
        id:curso.id,
        name:curso.titulo
        
      });
    });


    this.conteudoFiltros = this.conteudoList;
    //this.servicoFiltros = this.servicoList.filter(servico => servico.name.toLowerCase().indexOf("i") > -1);
    this.filteredConteudos = this.conteudoCtrl.valueChanges
      .pipe(
        startWith(''),
        map(conteudo => conteudo ? this._filterConteudos(conteudo) : this.conteudoList.slice())
      );

    this.progressConteudoShow=false;
    console.log("ConteudoList: " + JSON.stringify(data));
  }

  onResponseClonePesquisa(data: any): void {
    //throw new Error("Method not implemented.");
  }


  onResponsePesquisaFindById(data: any): void {
    //throw new Error("Method not implemented.");
  }

  onResponsePesquisaListByClienteId(data: any): void {
    this.conteudoList=[];
    data.forEach(pesquisa=>{
      this.conteudoList.push({
        servicoId:1,
        tipo:1,
        id:pesquisa.id,
        name:pesquisa.descricao
        
      });
    });


    this.conteudoFiltros = this.conteudoList;
    //this.servicoFiltros = this.servicoList.filter(servico => servico.name.toLowerCase().indexOf("i") > -1);
    this.filteredConteudos = this.conteudoCtrl.valueChanges
      .pipe(
        startWith(''),
        map(conteudo => conteudo ? this._filterConteudos(conteudo) : this.conteudoList.slice())
      );

    this.progressConteudoShow=false;
    console.log("ConteudoList: " + JSON.stringify(data));

  }




  onResponseCloneCursoToCliente(data: any): void {
    //throw new Error('Method not implemented.');
  }
}



