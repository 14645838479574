import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';
import { analyzeAndValidateNgModules } from '@angular/compiler';


import { IservicoEvents } from '../service/iservico-events';
import { IservicoService } from './iservico-service';
import { ServicoDaoService } from '../dao/servico-dao.service';

@Injectable({
  providedIn: 'root'
})
export class ServicoService implements IservicoService {

 
  private subject = new Subject<any>();

  private _super:IservicoEvents;

  constructor(private servicoDaoService: ServicoDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IservicoEvents){
      this._super = _super;
    }

   /**
     * 
     */
    save(servicoDTO):void{
      this.servicoDaoService.save(servicoDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseServicoSave(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }



    /**
     * 
     */
    findById(servicoId:number):void{
      this.servicoDaoService.findById(servicoId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseServicoFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
 



    /**
     * 
     */
    list():void{
      this.servicoDaoService.list()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseServicoList(event.data);
                    break;
                    
                  case 'downProgress':
                    this._super.onDownloadProgress();
                    console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
 
}