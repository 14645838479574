import { Routes } from '@angular/router';
// import { ListProdutoComponent } from './produto/application/list/list.produto.component';
// import { EditProdutoComponent } from './produto/application/edit/edit.produto.component';


// import { ListPedidoComponent } from './pedido/application/list/list.pedido.component';
 import { StoreEmaiswebComponent } from './emaisweb/application/store-emaisweb.component';



 export const salesChannelRoutes: Routes = [
//   {path: '',
//     children: [{
//                 path: 'produto',
//                 children:[{path:'',component: ListProdutoComponent},
//                           {path:'list',component: ListProdutoComponent},
//                           {path:'create',component: EditProdutoComponent},
//                           {path:'edit',component: EditProdutoComponent}
//                          ]
//                }]
//   },

//   {path: '',
//     children: [{
//                 path: 'pedido',
//                 children:[{path:'',component: ListPedidoComponent},
//                           {path:'list',component: ListPedidoComponent},
//                           {path:'create',component: MakerPedidoComponent},
//                           {path:'edit',component: MakerPedidoComponent}
//                          ]
//                }]
//   }



 ];

//{path:'edit/:id',component: EditProdutoComponent}