import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromEventPattern';

import { IprodutoEvents } from '../service/iproduto-events';

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { IprodutoService } from './iproduto-service';

import { ProdutoDaoService } from '../dao/produto-dao.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService implements IprodutoService {

  private subject = new Subject<any>();

  private _super:IprodutoEvents;

  constructor(private produtoDaoService: ProdutoDaoService) { 
    
  }

    /**
     * 
     * @param _super 
     * recebe referencia do objeto que esta assinando este servico
     */
    eventSubscribe(_super:IprodutoEvents){
      this._super = _super;
    }


   /**
     * 
     */
    save(produtoDTO):void{

     
      this.produtoDaoService.save(produtoDTO)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseProdutoSave(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    
    findById(produtoId: any): void {
      
      this.produtoDaoService.findById(produtoId)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));                  
                    this._super.onResponseProdutoFindById(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }


    listByCanalVenda(canalVenda: any): void {
      this.produtoDaoService.listByCanalVenda(canalVenda)
      .subscribe(event=>{
        switch (event.type) {
          case 'start':
            //console.log("request...");
            break;

          case 'response':
            //this.produtoList = event.data;
            //console.log("Resposta:  " + JSON.stringify(event.data));
            this._super.onResponseProdutoListByCanalVenda(event.data);
            break;
            
          case 'downloadProgress':
            this._super.onDownloadProgress();
            //console.log(`Download in progress! ${ event.data }Kb loaded`);
            break;
        }
      }, 
       error => console.log("error" + error)
    );

    }
 
 
    /**
     * 
     */
    list():void{
      this.produtoDaoService.list()
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseProdutoList(event.data);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    //console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
    


    uploadS3(produtoDTO: any, file: any): void {
      this.produtoDaoService.uploadS3(produtoDTO, file)
              .subscribe(event=>{
                switch (event.type) {
                  case 'start':
                    //console.log("start request...");
                    break;
        
                  case 'response':
                    //this.produtoList = event.data;
                    //console.log("Resposta:  " + JSON.stringify(event.data));
                    this._super.onResponseProdutoUpload(event.data);
                    break;
                  case 'uploadProgress':
                    this._super.onUploadProgress();
                    //console.log(`Upload in progress! ${ event.data }Kb loaded`);
                    break;
                    
                  case 'downloadProgress':
                    this._super.onDownloadProgress();
                    ///console.log(`Download in progress! ${ event.data }Kb loaded`);
                    break;
                }
              }, 
               error => console.log("error" + error)
            );
    }
  
}