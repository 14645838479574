import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AgmCoreModule } from '@agm/core';


import { FlexLayoutModule } from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';

//import { NgJsonEditorModule } from 'ang-jsoneditor'
//import { JSONEditorModule } from 'ng2-jsoneditor';
//import { JsonEditorModule } from 'ng2-json-editor';

//import { QuillModule } from 'ngx-quill'

//import { NgxEditorModule } from 'ngx-editor';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';


// import { MatColorPickerModule } from 'mat-color-picker';

import { ColorPickerModule } from 'ngx-color-picker';





import {
  MatSidenavModule,
  MatCardModule,
  MatMenuModule,
  MatCheckboxModule,
  MatIconModule,
  MatButtonModule,
  MatToolbarModule,
  MatTabsModule,
  MatListModule,
  MatTableModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatProgressBarModule,
  MatFormFieldModule
} from '@angular/material';





import { BidiModule } from '@angular/cdk/bidi';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorService } from './middleware/auth-interceptor.service';
import { DragScrollModule } from 'ngx-drag-scroll';
//import { QuillModule } from 'ngx-quill';


import { SalesChannelModule } from './eplus/sales-channel/sales-channel.module';
//import { MakerPedidoComponent } from './eplus/marketplace/pedido/application/maker-pedido.component';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent
   
    //MakerPedidoComponent

    
  ],
  imports: [
    
    BrowserModule,
    LayoutModule,

    // JsonEditorModule,

    ///NgxEditorModule,
    //AngularFontAwesomeModule,
   

    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    
    // QuillModule.forRoot({
    //   modules: {
    //     syntax: true,
    //     toolbar: [
    //       ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    //       ['blockquote', 'code-block'],
      
    //       [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    //       [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //       [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    //       [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    //       [{ 'direction': 'rtl' }],                         // text direction
      
    //       [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    //       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
    //       [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //       [{ 'font': [] }],
    //       [{ 'align': [] }],
      
    //       ['clean'],                                         // remove formatting button
      
    //       ['link', 'image', 'video']                         // link and image, video
    //     ]
    //   }
    // }),

    // MatColorPickerModule,
    ColorPickerModule,

    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    FlexLayoutModule,
    BidiModule,
    DragScrollModule,

    SalesChannelModule,


    AgmCoreModule.forRoot({ apiKey: 'YOURAPIKEY' }),
    PerfectScrollbarModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
